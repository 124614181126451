import React, {Fragment, useCallback, useEffect, useState} from "react";
import FormRow from "../Form/FormRow";
import TextInput from "../TextInput/TextInput";
import Form from "../Form/Form";
import FormSection from "../Form/FormSection";
import UploadDropzone, {UploadFile} from "../UploadDropzone/UploadDropzone";
import Button, {ButtonSize, ButtonStyle, ButtonType} from "../Button/Button";
import {WaveProductModel} from "../../types/WaveProductModel";
import Table from "../Table/Table";
import SaleTotalSum from "../SaleTotalSum/SaleTotalSum";
import {useHistory, useParams} from "react-router-dom";
import './AddSaleForm.css';
import {observer} from "mobx-react-lite";
import {RequestState} from "../../types/RequestState";
import {useStores} from "../../store";
import jsQR from "jsqr";
import {FnsTicketRequest} from "../../types/FnsTicketRequest";
import {AddSaleWithFnsTicketRequestModel} from "../../types/AddSaleWithFnsTicketRequestModel";
import WebcamCapture from "../WebcamCapture/WebcamCapture";
import ID from "../../helpers/id";
import Html5QrcodePlugin from "../Html5QrcodePlugin/Html5QrcodePlugin";

interface SelectedProduct {
    product: WaveProductModel;
    quantity: number;
}

interface AddSaleWithFnsTicketFormProps {
    waveProducts: WaveProductModel[];
    getWaveProducts: (waveId: number, search: string) => void;
    onAddSale: (waveId: number, request: AddSaleWithFnsTicketRequestModel, ok: () => void, error: (error: string) => void) => void;
    dealer: string;
    company: string;
    city: string;
    address: string;
    addSaleState: RequestState;
    onFnsTicketDrop: (waveId: number, fnsTicketRequests: FnsTicketRequest[]) => void;
    waveId: number;
}


const AddSaleWithFnsTicketForm = (props: AddSaleWithFnsTicketFormProps) => {
    const [selectedProducts, setSelectedProducts] = useState<SelectedProduct[]>(
        []
    );

    useEffect(() => {
        setAddress(props.address);
        setCompany(props.company);
        setCity(props.city);
        setDealer(props.dealer);
    }, []);

    const history = useHistory();
    const {store} = useStores();
    const [dealer, setDealer] = useState<string>("");
    const [company, setCompany] = useState<string>("");
    const [city, setCity] = useState<string>("");
    const [address, setAddress] = useState<string>("");
    const [files, setFiles] = useState<File[]>([]);
    const [note, setNote] = useState<string>("");
    const [agreementNumber, setAgreementNumber] = useState<string>("");
    const [agreementNumberError, setAgreementNumberError] = useState<boolean>(false);
    const [productsError, setProductsError] = useState<boolean>(false);
    const [filesError, setFilesError] = useState<boolean>(false);
    const [formSent, setFormSent] = useState<boolean>(false);
    const [fnsTickets, setFnsTickets] = useState<FnsTicketRequest[]>([]);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [scanResult, setScanResult] = useState<string>("");
    const [scanQrCodeMode, setScanQrCodeMode] = useState<boolean>(false);
    const [aspectRatio, setAspectRatio] = useState(4);


    // console.log('fl', files.length);
    useEffect(() => {
        setAgreementNumberError(formSent && agreementNumber === '');
    }, [agreementNumber, formSent]);

    useEffect(() => {
        if (formSent) {
            setFilesError(files.length === 0  && fnsTickets.length === 0);

        }
    }, [formSent, files]);

    useEffect(() => {
        if (formSent) {
            setProductsError(files.length > 0 && fnsTickets.length === 0);

        }
    }, [formSent, selectedProducts]);


    const addSale = () => {
        setFormSent(true);
        setProductsError(false);
        setFilesError(false);


        if (files.length > 0 && fnsTickets.length == 0) {
            setProductsError(true);
            return;
        }

        if (files.length == 0 && fnsTickets.length == 0) {
            setFilesError(true);
            return;
        }

        props.onAddSale(props.waveId,
            {
                fnsTickets: fnsTickets.filter(x => x.amount > 0),
                files
            },
            () => {
                store.participants.getBalance();
                history.push("/waves/" + props.waveId);
            },
            (error) => {
                setErrorMessage(error);
            }
        );
    };

    const codeToFnsRequest = (code: string): FnsTicketRequest => {

        const fnsTicket = Object.fromEntries(
            code.split('&').map(param => param.split('='))
        );

        const year = fnsTicket.t.slice(0, 4);
        const month = fnsTicket.t.slice(4, 6);
        const day = fnsTicket.t.slice(6, 8);
        const hours = fnsTicket.t.slice(9, 11);
        const minutes = fnsTicket.t.slice(11, 13);

        const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:00`;

        return {
            amount: fnsTicket.s,
            date: formattedDate,
            fiscalDocumentId: fnsTicket.i,
            fiscalSign: fnsTicket.fp,
            fn: fnsTicket.fn
        }
    }

    const onFnsTicketDrop = (files: File[]) => {

        // console.log('files-on', files.length)
        setFiles(files);
        let fnsTicketRequests: FnsTicketRequest[] = [];

        if (files.length == 0) {
            props.onFnsTicketDrop(props.waveId, []);
            return;
        }

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const reader = new FileReader();
            reader.onload = (e) => {
                const img = new Image();
                img.onload = () => {
                    const canvas = document.createElement("canvas");
                    const context = canvas.getContext("2d");
                    if (!context) return;
                    canvas.width = img.width;
                    canvas.height = img.height;
                    context.drawImage(img, 0, 0, img.width, img.height);
                    const imageData = context.getImageData(0, 0, img.width, img.height);
                    const code = jsQR(imageData.data, imageData.width, imageData.height);

                    console.log('code', code);
                    if (code) {
                        fnsTicketRequests.push(codeToFnsRequest(code.data));
                    } else {
                        fnsTicketRequests.push({
                            amount: 0,
                            date: "",
                            fiscalDocumentId: 0,
                            fiscalSign: 0,
                            fn: 0
                        });
                    }

                    if (fnsTicketRequests.length == files.length) {
                        // console.log('fnsTicketRequests: ', fnsTicketRequests);
                        setFnsTickets(fnsTicketRequests);
                        props.onFnsTicketDrop(props.waveId, fnsTicketRequests.filter(x => x.amount > 0));
                    }
                };
                img.src = e.target?.result as string;
            };
            reader.readAsDataURL(file);
        }
    }

    const selectedProductsTotalSum = () => {
        let sum = 0;
        props.waveProducts.forEach((p) => {
            sum += p.point * p.count;
        });
        return sum;
    };

    const onNewScanResult = (decodedText: string, decodedResult: any) => {
        // handle decoded results here

        console.log('decodedText', decodedText);
        console.log('decodedResult', decodedResult);

        setScanResult(decodedText);
        setScanQrCodeMode(false);

        if (decodedText) {
            const fnsTicket = codeToFnsRequest(decodedText);
            setFnsTickets([fnsTicket]);
            props.onFnsTicketDrop(props.waveId, [fnsTicket]);
        }
    };

    return (
        <Form>
            {productsError && <div className="add-sale__error">Не удалось распознать чек</div>}
            {filesError && <div className="add-sale__error">Приложите файлы, подтверждающие продажу</div>}
            {errorMessage && <div className="add-sale__error">{errorMessage}</div>}

            <FormRow>
                <TextInput
                    id={"dealer"}
                    label={"Дилер"}
                    onChange={setDealer}
                    value={dealer}
                />
                &nbsp;
                <Button onClick={() => history.push('/profile')} size={ButtonSize.Big} style={ButtonStyle.Primary}
                        type={[ButtonType.Transparent]}>Изменить</Button>
                {/*<NavLink className="add-sale__change-dealer-link" to="/profile">Изменить</NavLink>*/}
            </FormRow>
            <FormRow>
                <TextInput
                    id={"company"}
                    label={"Точка"}
                    onChange={setCompany}
                    value={company}
                />
            </FormRow>
            <FormRow>
                <TextInput
                    id={"city"}
                    label={"Город"}
                    onChange={setCity}
                    value={city}
                />
            </FormRow>
            <FormRow>
                <TextInput
                    id={"address"}
                    label={"Адрес точки"}
                    onChange={setAddress}
                    value={address}
                />
            </FormRow>

            <FormSection title={"Приложите чеки, подтверждающие продажу"}>

                <div style={{width: "100%", alignItems: "center", textAlign:"center",     marginBottom: "10px"}}>
                    <Button
                        disabled={props.addSaleState == RequestState.Pending || !store.participants.profile?.personalIncomeTaxData}
                        onClick={() => {
                            setScanQrCodeMode(!scanQrCodeMode);
                        }}
                        size={ButtonSize.Big}
                        style={ButtonStyle.Primary}
                        type={[ButtonType.Transparent]}
                    >
                        {!scanQrCodeMode ? "Отсканировать QR-код" : "Остановить сканирование"}
                    </Button>
                </div>
                <div style={{width: "100%", alignItems: "center", textAlign:"center", marginBottom: "10px"}}>или</div>
                <FormRow>
                    <UploadDropzone onDrop={onFnsTicketDrop} files={files}/>
                </FormRow>

            </FormSection>
            {scanQrCodeMode && <Html5QrcodePlugin
                aspectRatio={1}
                fps = {10}
                qrbox = {200}
                qrCodeSuccessCallback={onNewScanResult}
            />
            }

            <Fragment>
                <FormRow>
                    <Table
                        header={[
                            {data: "Товар"},
                            {data: "Артикул"},
                            {data: "Баллы"},
                            {data: "Кол-во"},
                        ]}
                        rows={props.waveProducts.map((product) => {
                            return {
                                key: product.id.toString(),
                                columns: [
                                    {data: product.name},
                                    {data: product.vendorCode},
                                    {data: product.point},
                                    {data: product.count}
                                ],
                            };
                        })}
                    />
                </FormRow>
                <FormRow>
                    <SaleTotalSum total={selectedProductsTotalSum()}/>
                </FormRow>
            </Fragment>
            <FormRow>
                <Button
                    disabled={props.addSaleState == RequestState.Pending || !store.participants.profile?.personalIncomeTaxData}
                    onClick={addSale}
                    size={ButtonSize.Big}
                    style={ButtonStyle.Primary}
                >
                    Отправить на проверку
                </Button>
            </FormRow>

        </Form>
    );
};

export default observer(AddSaleWithFnsTicketForm);
