import React, {useEffect, useState} from "react";
import "./UploadDropzone.css";
import {useDropzone} from "react-dropzone";
import ID from "../../helpers/id";
import Button, {ButtonSize, ButtonStyle, ButtonType} from "../Button/Button";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import cameraLogo from "../../assets/icons/camera.webp";
import WebcamCapture from "../WebcamCapture/WebcamCapture";

export interface UploadFile {
    id: string;
    preview: string;
}

interface UploadDropZoneProps {
    onDrop: (files: File[]) => void;
    files: File[];
}

interface FilesWithId {
    file: File;
    uploadFile: UploadFile;
}

const UploadDropzone = (props: UploadDropZoneProps) => {
    const [files, setFiles] = useState<FilesWithId[]>([]);
    const [webcamCaptureOpen, setWebcamCaptureOpen] = useState(false)


    useEffect(() => {
        console.log('files count', props.files.length)
        setFiles(
            props.files.map((file) => {
                return {uploadFile: {id: ID(), preview: URL.createObjectURL(file)}, file: file};
            })
        );
    }, [props.files]);

    useEffect(
        () => () => {
            files.forEach((file) => URL.revokeObjectURL(file.uploadFile.preview));
        },
        [files]
    );

    const deleteImage = (id: string) => {
        // setFiles(files.filter((x) => x.uploadFile.id !== id));
        props.onDrop(files.filter((x) => x.uploadFile.id !== id).map(x => x.file));
    };

    const {getRootProps, getInputProps, open} = useDropzone({
        accept: "image/*",
        noClick: true,
        onDrop: (acceptedFiles) => {
            // setFiles(
            //     acceptedFiles.map((file) => {
            //         return {id: ID(), preview: URL.createObjectURL(file)};
            //     })
            // );
            props.onDrop(acceptedFiles);
        }
    });

    return (
        <div className={"upload-dropzone"}>
             <div {...getRootProps({className: "upload-dropzone__dropzone"})}>
              <input {...getInputProps()} />
                 {!webcamCaptureOpen && <div className={"upload-dropzone__body"}>
                <TransitionGroup className={"upload-dropzone__thumbs"}>
                    {files!.map((file) => (
                        <CSSTransition
                            key={file.uploadFile.id}
                            timeout={500}
                            classNames={"upload-dropzone__thumb"}
                        >
                            <div className={"upload-dropzone__thumb"} key={file.uploadFile.id}>
                                <img src={file.uploadFile.preview}/>
                                <div
                                    onClick={() => {
                                        deleteImage(file.uploadFile.id);
                                    }}
                                    className={"upload-dropzone__thumb-close"}
                                >
                                    &#10006;
                                </div>
                            </div>
                        </CSSTransition>
                    ))}
                </TransitionGroup>

                <div className={"upload-dropzone__text"}>Перетащите файлы сюда</div>
                <div className={"upload-dropzone__text-small"}>
                  JPEG, PNG не более 10МБ
                </div>


                <div className={"upload-dropzone__button-wrapper"}>
                  {/*<Button onClick={() => {*/}
                  {/*    setWebcamCaptureOpen(true);*/}

                  {/*}}*/}
                  {/*        size={ButtonSize.Big}*/}
                  {/*        style={ButtonStyle.Primary}*/}
                  {/*        type={[ButtonType.Transparent]}>*/}
                  {/*  <img className="upload-dropzone__camera" src={cameraLogo} alt="camera"/>*/}
                  {/*</Button>*/}
                  <Button
                    onClick={() => {
                        open();
                    }}
                    size={ButtonSize.Big}
                    style={ButtonStyle.Primary}
                    type={[ButtonType.Transparent]}
                  >
                    Выбрать файлы
                  </Button>
                </div>
              </div>}
                {webcamCaptureOpen && <WebcamCapture onCapture={(url) => {

                    fetch(url!)
                        .then(res => res.blob())
                        .then(blob => {
                            const file = new File([blob], "capture.jpg", {type: "image/png"})
                            // setFiles([...files, file])
                            // onFnsTicketDrop([...files, file])
                            props.onDrop([...props.files, file]);
                        })

                    setWebcamCaptureOpen(false);
                }}
                />}
            </div>

        </div>
    );
};

export default UploadDropzone;
