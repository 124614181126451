import React, {useState} from 'react';
import './Accordion.css';
import classNames from "classnames";

function Accordion(props: any) {
    const [active, setActive] = useState(false);

    const titleClasses = classNames({
        "accordion-title": true,
        "accordion-title--active": active
    });

    const iconClasses = classNames({
        "accordion-title__icon": true,
        "accordion-title__icon--active": active
    });

    const contentClasses = classNames({
        "accordion-content": true,
        "accordion-content--active": active
    });

    return (
        <div className="accordion" id="accordionFaq">
            <div className={titleClasses}  onClick={() => setActive(!active)}>
                {props.children[0]}
                <div className={iconClasses}>
                </div>
            </div>
            <div className={contentClasses}>
                {active && props.children[1]}
            </div>
        </div>
    );
}

export default Accordion;