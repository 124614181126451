import {API_URL, CLIENT_ID, KEY} from "../constants";

const getToken = (): string => {
    const ls = JSON.parse(
        sessionStorage.getItem(
            KEY
        ) || "{}"
    );

    if (ls.access_token) {
        return ls.access_token;
    }

    return "";
};

export const httpGet = (url: string): Promise<any> => {
    return fetch(API_URL + url, {
        headers: {
            Authorization: "Bearer " + getToken(),
            'X-Client': CLIENT_ID
        },
    }).then((response) => {
        return response.json();
    }).catch(error => {
        throw error;
    });
};

export const httpPostFormData = (url: string, body: FormData): Promise<any> => {
    return fetch(API_URL + url, {
        method: 'POST',
        headers: {
            Authorization: "Bearer " + getToken(),
            'X-Client': CLIENT_ID
        },
        body
    }).then((response) => {
        if (!response.ok) {
            if (response.status === 400 || response.status === 422) {
                return response.text().then(text => {
                    return Promise.reject(text);
                });
            }
            return Promise.reject("Произошла ошибка. Попробуйте позже");
        }
        return response.json();
    }).catch(error => {
        if (typeof error === 'string') {
            return Promise.reject(error);
        }

        return Promise.reject("Произошла ошибка. Попробуйте позже");
    });
};

export const httpPostJson = (url: string, json: string, method: string = 'POST'): Promise<any> => {
    return fetch(API_URL + url, {
        method: method,
        headers: {
            Authorization: "Bearer " + getToken(),
            'Content-Type': 'application/json',
            'X-Client': CLIENT_ID
        },
        body: json
    }).then((response) => {
        return response.json();
    }).catch(error => {
        throw error;
    });
};

export const httpPostJsonFile = (url: string, json: string): Promise<Response> => {
    return fetch(API_URL + url, {
        method: 'POST',
        headers: {
            Authorization: "Bearer " + getToken(),
            'Content-Type': 'application/json',
            'X-Client': CLIENT_ID
        },
        body: json
    }).catch(error => {
        throw error;
    });
};