import React from 'react';
import ImageViewer from "react-simple-image-viewer";
import {createPortal} from "react-dom";
import "./ProductCardImages.css";

export interface ProductCardImagesProps {
    src: string[];
}

const ProductCardImages = ({src}: ProductCardImagesProps) => {

    const [currentImage, setCurrentImage] = React.useState(0);
    const [isViewerOpen, setIsViewerOpen] = React.useState(false);

    return (
        <div className={"product-card-images"}>
            {isViewerOpen &&
                createPortal(<ImageViewer
                    src={src}
                    currentIndex={currentImage}
                    disableScroll={true}
                    backgroundStyle={{
                        zIndex: 1005,
                        backgroundColor: "rgba(0,0,0,0.75)"
                    }}
                    closeOnClickOutside={true}
                    onClose={() => {
                        setIsViewerOpen(false)
                    }}
                />, document.body)}

            {src.map((x, index) => (
                <img
                    className={"product-card-images__image"}
                    src={x}
                    alt={""}
                    key={index}
                    onClick={() => {
                        setCurrentImage(index);
                        setIsViewerOpen(true);
                    }}
                />
            ))}
        </div>
    );
};

export default ProductCardImages;