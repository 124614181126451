import React, {useEffect, useState} from "react";
import Webcam from "react-webcam";
import Button, {ButtonSize, ButtonStyle, ButtonType} from "../Button/Button";
import cameraLogo from "../../assets/icons/camera.webp";

const MOBILE_MAX_WIDTH = 786;

let videoConstraintsInit = {
    facingMode: {exact: "environment"},
};

export interface WebcamCaptureProps {
    onCapture: (imageSrc: string | null) => void;
}

const WebcamCapture = ({onCapture}: WebcamCaptureProps) => {
    const webcamRef = React.useRef(null);
    const [imgSrc, setImgSrc] = React.useState(null);
    const [isMobile, setIsMobile] = useState<any>({
        matches: window.innerWidth <= MOBILE_MAX_WIDTH ? true : false
    });

    const [videoConstraints, setVideoConstraints] = useState({});

    useEffect(() => {

        console.log('isMobile', isMobile);
        if (isMobile.matches) {
            setVideoConstraints({
                width: 100,
                height: 120,
                // @ts-ignore
                facingMode: {exact: "environment"},
            })
        } else {
            setVideoConstraints({
                width: 480,
                height: 420,
                // facingMode: {exact: "environment"},
            })
        }


    }, [isMobile]);


    const capture = React.useCallback(() => {
        // @ts-ignore
        const imageSrc = webcamRef.current.getScreenshot();
        setImgSrc(imageSrc);
        onCapture(imageSrc);
    }, [webcamRef, setImgSrc]);

    return (
        <>
            <Webcam
                audio={false}
                ref={webcamRef}
                videoConstraints={videoConstraints}
                screenshotFormat="image/jpeg"
            />
            <br/>
            <Button onClick={capture}
                    size={ButtonSize.Big}
                    style={ButtonStyle.Primary}
                    type={[ButtonType.Transparent]}>
                <img className="upload-dropzone__camera" src={cameraLogo} alt="camera"/>
            </Button>
        </>
    );
};


export default WebcamCapture;