import {CameraDevice, Html5Qrcode, Html5QrcodeScanner} from 'html5-qrcode';
import React, {useEffect, useMemo, useState} from 'react';
import Slider from "../Slider/Slider";
import './Html5QrcodePlugin.css';
import {Html5QrcodeCameraScanConfig} from "html5-qrcode/src/html5-qrcode";
import SelectList from "../SelectList/SelectList";
import FormRow from '../Form/FormRow';

const qrcodeRegionId = "html5qr-code-full-region";


export interface Html5QrcodePluginProps {
    fps: number,
    qrbox?: number,
    aspectRatio?: number,
    disableFlip?: boolean,
    verbose?: boolean,
    qrCodeSuccessCallback: (decodedText: string, decodedResult: any) => void
}

// Creates the configuration object for Html5QrcodeScanner.
const createConfig = (props: any) => {
    let config: Html5QrcodeCameraScanConfig = {
        fps: props.fps
    };

    if (props.fps) {
        config.fps = props.fps;
    }
    if (props.qrbox) {
        config.qrbox = props.qrbox;
    }
    if (props.aspectRatio) {
        config.aspectRatio = props.aspectRatio;
    }
    if (props.disableFlip !== undefined) {
        config.disableFlip = props.disableFlip;
    }

    return config;
};
let html5QrCode: Html5Qrcode;

const Html5QrcodePlugin = (props: Html5QrcodePluginProps) => {
    const [devices, setDevices] = useState<CameraDevice[]>([]);
    const [selectedDevice, setSelectedDevice] = useState<CameraDevice | null>(null);

    useEffect(() => {
        if(!selectedDevice) {
            return;
        }

        const config = createConfig(props);
        html5QrCode.stop().then(() => {
            html5QrCode.start(
                selectedDevice.id,
                config,
                (decodedText, decodedResult) => {
                    // do something when code is read
                    // html5QrCode.stop().then(() => {
                    //     props.qrCodeSuccessCallback(decodedText, decodedResult);
                    // });
                    props.qrCodeSuccessCallback(decodedText, decodedResult);
                },
                (errorMessage) => {
                    // parse error, ignore it.
                })
                .catch((err) => {
                    // Start failed, handle it.
                });

        });
    }, [selectedDevice]);

    useEffect(() => {
        Html5Qrcode.getCameras().then((devices) => {
            setDevices(devices);

            // when component mounts
            const config = createConfig(props);
            // Suceess callback is required.
            if (!(props.qrCodeSuccessCallback)) {
                throw "qrCodeSuccessCallback is required callback.";
            }

            html5QrCode = new Html5Qrcode(qrcodeRegionId);
            console.log("selectedDevice", selectedDevice);
            html5QrCode.start(
                { facingMode: "environment"},
                config,
                (decodedText, decodedResult) => {
                    // do something when code is read
                    // html5QrCode.stop().then(() => {
                    //     props.qrCodeSuccessCallback(decodedText, decodedResult);
                    // });
                    props.qrCodeSuccessCallback(decodedText, decodedResult);
                },
                (errorMessage) => {
                    // parse error, ignore it.
                })
                .catch((err) => {
                    // Start failed, handle it.
                });

            // cleanup function when component will unmount
        });
        return () => {
            html5QrCode.stop().then(() => {
            });
        };

    }, []);

    return (
        // <div style={{width: "100%"}} id={qrcodeRegionId}/>
        <div >
            <div style={{marginBottom: "10px"}}>

                <SelectList<CameraDevice>
                    label="Выберите камеру"
                    id={"camera-select"}
                    renderValue={(item) => item.label}
                    onSelect={(value) => setSelectedDevice(value)}
                    value={selectedDevice!}
                    items={devices}
                />
            </div>
            <div className={"html5-qr-code-plugin-wrapper"}>
                <div className={"html5-qr-code-plugin__title"}>

                </div>
                <div style={{width: "100%"}} id={qrcodeRegionId}/>
                {/*<div className={"html5-qr-code-plugin__slider"}>*/}
                {/*    <Slider max={5} min={1} step={0.1} value={props.aspectRatio} onChange={(value) => {*/}
                {/*    }}*/}
                {/*    />*/}
                {/*</div>*/}
            </div>
        </div>
    );
};

export default Html5QrcodePlugin;