import Alert, {AlertType} from "../Alert/Alert";
import AlertRow from "../Alert/AlertRow";
import AlertColumn from "../Alert/AlertColumn";
import Title from "../Title/Title";
import TextInput from "../TextInput/TextInput";
import FlexSeparator from "../Flex/FlexSeparator";
import Button, {ButtonSize, ButtonStyle, ButtonType} from "../Button/Button";
import {RequestState} from "../../types/RequestState";
import React, {useEffect, useState} from "react";
import {useStores} from "../../store";
import {observer} from "mobx-react-lite";

const AddEmail = () => {
  const {store} = useStores();
  const [email, setEmail] = useState<string | undefined>();
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);

  useEffect(() => {
    if (store.participants) {
      setEmail(store.participants.profile?.email);
    }
  }, [store.participants.profile]);

  const validateEmail = (value: string) => {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value);
  }

  const saveEmail = () => {
    setEmailSent(true);
    // console.log('emailSent && emailError', emailSent, emailError)
    if (email && !validateEmail(email)) {
      return;
    }

    if (store.participants.profile && email) {
      // console.log('addEmail')
      store.participants.addEmail(email)
        .then(() => {
          // console.log('then addEmail')
          store.participants.profile!.email = email;
        });

    }
  }

  if (store.participants.profile?.email)
    return <></>;

  return (<div className={"profile-page__alert"}><Alert type={AlertType.Warning}>
      <AlertRow type={"end"}>
        <AlertColumn>
          <Title as={"h4"} m={8}>
            Введите свою электронную почту
          </Title>
          <TextInput
            label={"Электронная почта"}
            id={"email"}
            editable
            isError={emailSent && emailError}
            onChange={(value) => {
              setEmailError(!validateEmail(value));
              setEmail(value);
            }}
            value={email}
          />
        </AlertColumn>
        <FlexSeparator/>
        <AlertColumn>
          <Button
            onClick={saveEmail}
            size={ButtonSize.Big}
            style={ButtonStyle.Black}
            type={[ButtonType.Transparent]}
            pending={store.participants.resendEmailConfirmLinkState === RequestState.Pending}
          >
            Сохранить
          </Button>
        </AlertColumn>
      </AlertRow>
    </Alert></div>
  );
};

export default observer(AddEmail);