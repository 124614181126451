import {WaveModel} from "../types/WaveModel";
import {httpGet, httpPostFormData, httpPostJson,} from "../helpers/http";
import {WaveProductModel} from "../types/WaveProductModel";
import {AddSaleRequestModel} from "../types/AddSaleRequestModel";
import {SaleModel} from "../types/SaleModel";
import {FnsTicketRequest} from "../types/FnsTicketRequest";
import {AddSaleWithFnsTicketRequestModel} from "../types/AddSaleWithFnsTicketRequestModel";

export const getWave = (id: number): Promise<WaveModel> => {
    return httpGet("/api/v1/waves/" + id.toString()).then((response) => {
        return response;
    });
};

export const getWaves = (): Promise<WaveModel[]> => {
    return httpGet("/api/v1/waves").then((response) => {
        return response.waves;
    });
};

export const getWaveProductsByFnsTickets = (id: number, request: FnsTicketRequest[]): Promise<WaveProductModel[]> => {
    return httpPostJson("/api/v1/waves/" + id + "/products-by-fns-tickets", JSON.stringify(request)).then((response) => {
        return response.waveProducts;
    });
};

export const getWaveProducts = (waveId: number, vendorCodeStartsWith: string, vendorCodes: string[]): Promise<WaveProductModel[]> => {
    let url = "/api/v1/waves/" + waveId + `/products?vendorCodeStartsWith=${vendorCodeStartsWith}`;
    if (vendorCodes.length > 0) {
        url += `&${vendorCodes.map(vc => 'vendorCodes=' + vc).join('&')}`;
    }
    return httpGet(url).then((response) => {
        return response.waveProducts;
    });
};

export const getSales = (waveId: number): Promise<SaleModel[]> => {
    return httpGet("/api/v1/waves/" + waveId + `/sales`).then((response) => {
        return response.sales;
    });
};

export const addSale = (
    waveId: number,
    request: AddSaleRequestModel
): Promise<Response> => {
    const formData = new FormData();
    // console.log(request)
    // console.log(JSON.stringify(request.additionalSaleInfo))
    formData.append("additionalSaleInfo", JSON.stringify(request.additionalSaleInfo));
    formData.append("note", request.note);
    formData.append("agreementNumber", request.agreementNumber);
    formData.append("products", JSON.stringify(request.products));
    request.files.forEach((file) => {
        formData.append("proofFiles", file);
    });
    return httpPostFormData(`/api/v1/waves/${waveId}/sales`, formData);
};

export const addSaleWithFnsTickets = (
    waveId: number,
    request: AddSaleWithFnsTicketRequestModel
): Promise<Response> => {
    const formData = new FormData();
    formData.append("fnsTickets", JSON.stringify(request.fnsTickets));
    request.files.forEach((file) => {
        formData.append("proofFiles", file);
    });

    return httpPostFormData(`/api/v1/waves/${waveId}/sales-with-fns-tickets`, formData);
}