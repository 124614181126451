import React, {useEffect, useRef, useState} from "react";
import {NavLink} from "react-router-dom";
import "./NavBar.css";
import CurrentClientProfile from "../../client-profile";

function getWindowDimensions() {
  const { outerWidth: width, outerHeight: height } = window;
  return {
    width,
    height,
  };
}

export interface NavBarItemModel {
  id: string;
  name: string;
  to: string;
  visible: boolean;
}

const NavBar = () => {
  let menu: NavBarItemModel[] = [
    { id: "1", name: CurrentClientProfile.title || "Каталог подарков", to: "/products", visible: true },
    { id: "2", name: "Мерчи", to: "/merch", visible: true },
    { id: "6", name: "Активировать код", to: "/profile/codes", visible: CurrentClientProfile.userProfile.codes },
    { id: "2", name: "Акция", to: "/waves/1", visible: CurrentClientProfile.sales },
    { id: "3", name: "Поддержка", to: "/support", visible: true},
    { id: "4", name: "Брошюры", to: "/booklets", visible: CurrentClientProfile.booklets },
    { id: "5", name: "О компании", to: "/about-products", visible: CurrentClientProfile.about }
  ];

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [quantityHideItem, setQuantityHideItem] = useState(0);
  const [subMenu, setSubMenu] = useState<NavBarItemModel[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const windowDimensions = getWindowDimensions();

    if (windowDimensions.width > 1108) {
      setQuantityHideItem(0);
    }

    if (windowDimensions.width < 1108 && windowDimensions.width > 820) {
      setQuantityHideItem(2);
    }

    if (windowDimensions.width < 820) {
      setQuantityHideItem(3);
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event: any) => {
    if (wrapperRef !== null && wrapperRef.current !== null) {
      if (!wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
  };

  return (
    <div className={"nav-bar"}>
      {menu.filter(x => x.visible).map(
        (item, index) =>
          menu.length - index > quantityHideItem && (
            <div key={item.id} className={"nav-bar__item"}>
              <NavLink className={"nav-bar__link"} to={item.to}>
                {item.name}
              </NavLink>
            </div>
          )
      )}

      {quantityHideItem !== 0 && (
        <div className={"nav-bar__item"}>
          <div
            className={"nav-bar-dropbox-link"}
            ref={wrapperRef}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <div className={"nav-bar-dropbox-link__header"}>Еще</div>
            {isOpen && (
              <div className={"nav-bar-dropbox-link__dropbox"}>
                {menu.filter(x => x.visible).map(
                  (item, index) =>
                    menu.length - index < quantityHideItem + 1 && (
                      <div className={"nav-bar-dropbox-link__item"}>
                        <NavLink
                          className={"nav-bar-dropbox-link__link"}
                          to={item.to}
                        >
                          {item.name}
                        </NavLink>
                      </div>
                    )
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default NavBar;
