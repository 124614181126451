import {AddIncomeTaxDataRequestModel} from "../types/AddIncomeTaxDataRequestModel";
import {httpGet, httpPostFormData, httpPostJson} from "../helpers/http";
import {Address, ProfileModel} from "../types/ProfileModel";
import {EventModel, EventType} from "../types/EventModel";
import {IncreaseBalanceEventModel} from "../types/IncreaseBalanceEventModel";
import {OrderEventModel} from "../types/OrderEventModel";
import moment from "moment";
import {ROOT_HOST} from "../constants";

export const confirmEmail = (token: string): Promise<Response> => {
    return httpPostJson(
        "/api/v1/participants/confirm-email",
        JSON.stringify({token})
    );
};

export const resendEmailConfirmLink = (): Promise<Response> => {
    return httpPostJson(
        "/api/v1/participants/resend-email-confirm-link?returnUrl=" + encodeURIComponent(ROOT_HOST + "/confirm-email"), ""
    );
};

export const changeEmail = (newEmail: string) : Promise<Response> => {
    return httpPostJson(
        "/api/v1/participants/email",
        JSON.stringify({email: newEmail})
    );
}


export const upsertDeliveryAddress = (address: Address) : Promise<Response> => {
    return httpPostJson(
        "/api/v1/participants/delivery-address",
        JSON.stringify({deliveryAddress: address})
    );
}

export const getProfile = (): Promise<ProfileModel> => {
    return httpGet("/api/v1/participants/profile")
        .then((response) => {
            let profile: ProfileModel = {
                personalIncomeTaxData: null,
                userName: "",
                dealer: "",
                company: "",
                city: "",
                surname: "",
                firstname: "",
                lastname: "",
                rule: "",
                pdn: "",
                spam: "",
                policy: "",
                pointAddress: "",
                phoneNumber: "",
                email: "",
                emailConfirmed: false,
                cardNumber: "",
                client: {
                    id: -1,
                    name: "",
                    baseUrl: "",
                    isNeedAcceptOffer: false
                },
                deliveryAddress: {
                    aptNo: "",
                    building: "",
                    city: "",
                    region: "",
                    street: "",
                    zipCode: "",
                    raw: ""
                }
            };

            response.profile.forEach((entry: any) => {
                if (entry.type === "userName") profile.userName = entry.value;
                if (entry.type === "dealer") profile.dealer = entry.value;
                if (entry.type === "company") profile.company = entry.value;
                if (entry.type === "city") profile.city = entry.value;
                if (entry.type === "surname") profile.surname = entry.value;
                if (entry.type === "firstname") profile.firstname = entry.value;
                if (entry.type === "lastname") profile.lastname = entry.value;
                if (entry.type === "rule") profile.rule = entry.value;
                if (entry.type === "pdn") profile.pdn = entry.value;
                if (entry.type === "spam") profile.spam = entry.value;
                if (entry.type === "pointAddress") profile.pointAddress = entry.value;
                if (entry.type === "phoneNumber") profile.phoneNumber = entry.value;
                if (entry.type === "email") profile.email = entry.value;
                if (entry.type === "emailConfirmed") profile.emailConfirmed = entry.value === "True";
                if (entry.type === "acceptOffer") profile.acceptOffer = entry.value;
            });

            profile.userName = response.username;
            profile.personalIncomeTaxData = response.personalIncomeTax;
            profile.cardNumber = response.cardNumber;
            profile.client = response.client;
            profile.deliveryAddress = response.deliveryAddress || profile.deliveryAddress;

            return profile;
        })
        .catch((error) => {
            throw error;
        });
};

export const getBalance = (): Promise<number> => {
    return httpGet("/api/v1/participants/balance")
        .then((response) => {
            return response.balance;
        })
        .catch((error) => {
            throw error;
        });
};

export const getEvent = (): Promise<EventModel[]> => {
    return Promise.all([
        httpGet("/api/v1/payments/increase-entries"),
        httpGet("/api/v1/orders"),
    ])
        .then(([entries, orders]) => {

            let e = (entries as unknown) as IncreaseBalanceEventModel[];
            let o = (orders as unknown) as OrderEventModel[];

            let events: EventModel[] = []

            e.forEach((entry) => {
                events.push({
                    date: entry.createdDate,
                    type: EventType.IncreaseBalance,
                    increaseBalance: entry,
                    order: null
                })
            });
            o.forEach((order) => {
                events.push({date: order.createdDate, type: EventType.Order, increaseBalance: null, order: order})
            });

            events.sort(function (a, b) {
                return moment(b.date).unix() - moment(a.date).unix();
            });

            return events
        })
        .catch((err) => {
            console.log(err);
            return err
        });
};

export const updateDealer = (dealer: string) => {
    const formData = new FormData();
    formData.append("dealerName", dealer);
    return httpPostFormData(
        "/api/v1/participants/dealer-name",
        formData
    );
}



export const acceptOffer = (): Promise<Response> => {
    return httpPostJson(
        "/api/v1/participants/acceptOffer", ""
    );
};


export const updateCardNumber = (cardNumber: string) => {
    const formData = new FormData();
    formData.append("cardNumber", cardNumber);
    return httpPostFormData(
        "/api/v1/participants/card-number",
        formData
    );
}

export const addEmail = (email: string) => {
    const formData = new FormData();
    formData.append("email", email);
    formData.append("returnUrl", ROOT_HOST + "/confirm-email");
    return httpPostFormData(
        "/api/v1/participants/email",
        formData
    );
}


export const addIncomeTaxData = (
    request: AddIncomeTaxDataRequestModel
): Promise<Response> => {
    const formData = new FormData();

    formData.append("fio", request.fio);
    formData.append("passportIssueDate", request.passportIssueDate.toString());
    formData.append("passportNumber", request.passportNumber);
    formData.append("passportSeries", request.passportSeries);
    formData.append("passportIssuedBy", request.passportIssuedBy);
    formData.append("passportDepartmentCode", request.passportDepartmentCode);
    formData.append("birthday", request.birthday.toString());
    formData.append("inn", request.inn);
    formData.append("passportAddressRegion", request.passportAddressRegion);
    formData.append("passportAddressCity", request.passportAddressCity);
    formData.append("passportAddressStreet", request.passportAddressStreet);
    formData.append("passportAddressBuilding", request.passportAddressBuilding);
    formData.append("passportAddressAptNo", request.passportAddressAptNo);

    request.scanPassportFiles.forEach((file) => {
        formData.append("scanPassportFiles", file);
    });

    return httpPostFormData(
        "/api/v1/participants/personal-income-tax-data",
        formData
    );
};
