import React, {useEffect} from "react";
import AddSaleForm from "../components/Forms/AddSaleForm";
import {useStores} from "../store";
import {observer} from "mobx-react-lite";
import AddSaleWithFnsTicketForm from "../components/Forms/AddSaleWithFnsTicketForm";

const AddSaleWithFnsTicketFormContainer = () => {
  const { store } = useStores();

  useEffect(()=>{
    store.waves.getWaves()
  },[])

  return (
    <AddSaleWithFnsTicketForm
      getWaveProducts={store.waves.getProducts}
      waveProducts={store.waves.products}
      onAddSale={store.waves.addSaleWithFnsTickets}
      addSaleState={store.waves.addSaleState}
      dealer={store.participants.profile?.dealer || ""}
      address={store.participants.profile?.pointAddress || ""}
      company={store.participants.profile?.company || ""}
      city={store.participants.profile?.city || ""}
      onFnsTicketDrop={store.waves.getWaveProductsByFnsTickets}
      waveId={store.waves.wave?.id || 0}
    />
  );
};

export default observer(AddSaleWithFnsTicketFormContainer);
