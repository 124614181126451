import classNames from "classnames";
import React, {useState} from "react";
import {NavLink} from "react-router-dom";
import {NavBarItemModel} from "../../types/NavBarItemModel";
import AddSaleButton from "../AddSaleButton/AddSaleButton";
import HamburgerButton from "../HamburgerButton/HamburgerButton";
import ModalWindow from "../ModalWindow/ModalWindow";
import "./NavBarMobile.css";
import CurrentClientProfile from "../../client-profile";
import {CLIENT_ID} from "../../constants";

const NavBarMobile = () => {
  const menu: NavBarItemModel[] = [
    { id: "0", name: "Каталог", to: "/products", visible: true },
    { id: "1", name: "Мерчи", to: "/merch", visible: true },
    { id: "1", name: "Поддержка", to: "/support", visible: true },
    { id: "8", name: "Активировать код", to: "/profile/codes", visible: CurrentClientProfile.userProfile.codes },
    { id: "2", name: CLIENT_ID == "nlmk-catalog-webui" ? "Заказы" : "События", to: "/events", visible: true },
    { id: "3", name: "Акция", to: "/waves/1", visible: CurrentClientProfile.sales},
    { id: "4", name: "Брошюры", to: "/booklets", visible: CurrentClientProfile.booklets },
    { id: "5", name: "Настройка профиля", to: "/profile", visible: true},
    { id: "6", name: "О компании", to: "/about-products", visible: CurrentClientProfile.about},
    { id: "7", name: "Выйти", to: "/authentication/logout", visible: true },
  ];

  const [isOpen, setIsOpen] = useState(false);

  const closeModalWindow = () => {
    setIsOpen(false);
  };

  const openModalWindow = () => {
    setIsOpen(true);
  }

  return (
    <div className={"nav-bar-mobile"}>
      <div className={"nav-bar-mobile__button"}>
        <HamburgerButton
          open={isOpen}
          onClick={openModalWindow}
        />
      </div>

      {isOpen && (
        <ModalWindow
          contentClassName="nav-bar-mobile__modal"
          onClickOutside={closeModalWindow}
        >
          <div className="nav-bar-mobile__closeBtn" onClick={closeModalWindow}></div>
          <div className="nav-bar-mobile__items">
            {menu.filter(x => x.visible).map((item) => (
              <div key={item.id} className="nav-bar-mobile__item" onClick={closeModalWindow}>
                <NavLink className={classNames("nav-bar__link", "nav-bar-mobile__link")} to={item.to} exact={true}>
                  {item.name}
                </NavLink>
              </div>
            ))}
          </div>
          <div onClick={closeModalWindow}>
            <AddSaleButton />
          </div>
        </ModalWindow>
      )}
    </div>
  );
};

export default NavBarMobile;
