import React, {ReactNode, useEffect, useState} from "react";
import "./Datalist.css";
import TextInput from "../TextInput/TextInput";
import Button, {ButtonSize, ButtonStyle, ButtonType} from "../Button/Button";
import {CSSTransition} from "react-transition-group";

export interface DataListItem {
  id: string;
  data: ReactNode;
}

interface DatalistProps {
  label: string;
  id: string;
  onChangeText: (value: string) => void;
  multiselect?: boolean;
  items: DataListItem[];
  selectedItemIds: string[];
  add: (id: string) => void;
  remove: (id: string) => void;
  readonly?: boolean;
}

const Datalist = (props: DatalistProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [textValue, setTextValue] = useState("");

  useEffect(() => {
    setSelectedIds(props.selectedItemIds);
  }, []);

  const onChange = (value: string) => {
    setTextValue(value);
    if (value.length >= 3) {
      props.onChangeText(value);
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  const switchSelected = (id: string) => {
    const index = selectedIds.indexOf(id);
    if (index === -1) {
      props.add(id);
      setSelectedIds([...selectedIds, id]);
      return;
    }
    props.remove(id);
    setSelectedIds(selectedIds.filter((item) => item !== id));
  };

  const choose = () => {
    setTextValue("");
    setIsOpen(false);
    setSelectedIds([]);
  };

  return (
    <div className={"data-list"}>
      {!props.readonly && <div className={"data-list__text-input"}>
        <TextInput
          value={textValue}
          label={props.label}
          id={props.id}
          onChange={onChange}
          editable
        />
      </div>}
      <CSSTransition
        in={isOpen}
        unmountOnExit
        classNames="data-list__data"
        timeout={0}
      >
        <div className={"data-list__data"}>
          <div className={"data-list__data-container"}>
            {props.items.map((item) => (
              <div
                key={item.id}
                className={"data-list__item"}
                onClick={() => {
                  switchSelected(item.id);
                }}
              >
                <div className={"data-list__check"}>
                  <input
                    type="checkbox"
                    checked={selectedIds.indexOf(item.id) !== -1}
                    onChange={(e) => {}}
                  />
                </div>
                <div className={"data-list__element"}>{item.data}</div>
              </div>
            ))}
          </div>

          <div className={"data-list__button"}>
            <Button
              onClick={choose}
              size={ButtonSize.Big}
              style={ButtonStyle.Primary}
              type={[ButtonType.Transparent]}
            >
              Выбрать
            </Button>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default Datalist;
