import React, {useState} from "react";
import Button, {ButtonSize, ButtonStyle, ButtonType} from "../Button/Button";
import TextInput from "../TextInput/TextInput";
import {observer} from "mobx-react-lite";
import {useStores} from "../../store";
import {RequestState} from "../../types/RequestState";
import "./AddDeliveryAddress.css";
import {NavLink} from "react-router-dom";
import FormRow from "../Form/FormRow";
import Form from "../Form/Form";

interface AddDeliveryAddressProps {
    editMode?: boolean;
}

const AddDeliveryAddress = (props: AddDeliveryAddressProps) => {

    const {store} = useStores();

    let deliveryAddress = store.participants.profile?.deliveryAddress;

    // console.log('deliveryAddress', deliveryAddress);

    const [editMode, setEditMode] = React.useState(props.editMode);
    const [editable, setEditable] = useState<boolean>(props.editMode ?? true);

    if (deliveryAddress == undefined) {
        return <>dddd</>
    }

    if (editMode) {
        return <>
            <Form title={"Адрес доставки"}>
                <FormRow>
                    <TextInput
                        label={"Почтовый индекс"}
                            id={"deliveryAddressZipCode"}
                        onChange={(value) => deliveryAddress!.zipCode = value}
                        value={deliveryAddress.zipCode}
                        editable={editable}
                    />

                    <TextInput
                        label={"Регион"}
                        id={"deliveryAddressRegion"}
                        onChange={(value) => deliveryAddress!.region = value}
                        value={deliveryAddress.region}
                        editable={editable}
                    />

                </FormRow>
                <FormRow>
                    <TextInput
                        label={"Город"}
                        id={"deliveryAddressCity"}
                        onChange={(value) => deliveryAddress!.city = value}
                        value={deliveryAddress.city}
                        editable={editable}
                    />
                </FormRow>
                <FormRow>
                    <TextInput
                        label={"Улица"}
                        id={"deliveryAddressStreet"}
                        onChange={(value) => deliveryAddress!.street = value}
                        value={deliveryAddress.street}
                        editable={editable}
                    />
                </FormRow>
                <FormRow>
                    <TextInput
                        label={"Дом"}
                        id={"deliveryAddressBuilding"}
                        onChange={(value) => deliveryAddress!.building = value}
                        value={deliveryAddress.building}
                        editable={editable}
                    />
                    <TextInput
                        label={"Квартира"}
                        id={"deliveryAddressAptNo"}
                        onChange={(value) => deliveryAddress!.aptNo = value}
                        value={deliveryAddress.aptNo}
                        editable={editable}
                    />
                </FormRow>
            </Form>
            <Button onClick={() => {

                store.participants.upsertDeliveryAddress();

            }} size={ButtonSize.Big} style={ButtonStyle.Primary}
                    pending={store.participants.upsertDeliveryAddressState == RequestState.Pending}>Сохранить</Button>
        </>
    }

    return (
        <div className={"delivery-address-view"}>
            <div>Адрес доставки:</div>
            <div>{store.participants.profile?.deliveryAddress?.raw}</div>
            <div className={"edit__link"}>
                <NavLink to={"/profile"}>Редактировать</NavLink>
            </div>
        </div>
    )
};

export default observer(AddDeliveryAddress);